.App {
  margin: 50px auto;
  max-width: 400px;
  background-color: lightblue;
  padding: 20px;
  border-radius: 10px;
}

body {
  background: lightgrey;
}
h1 {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #4d4d4d;
  margin: 0 0 20px;
}
div {
  margin-bottom: 10px;
}
